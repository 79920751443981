import { Fragment, useEffect, useMemo, useState } from "react";
import { EmptyContent, FloatingForm, NavBar, Table } from "../../components";

//Styles
import "./style.scss";

//Icons
import { CgFileDocument } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { useStore } from "../../@core/hooks";
import { useUpdateStore } from "../../@core/hooks/mutation/store";
import { STORE_STATUS, WorkingHours } from "../../@core/models";
import { setStore } from "../../@core/slice";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { numberToDay } from "../../utils/number-to-day.util";
import { AddStoreForm } from "./add-store-form.component";
import { useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { EditStoreForm } from "./update/edit-store-form.component";
import { useQueryClient } from "react-query";
import { Box, Typography } from "@mui/material";

function Preferences() {
  const { t ,i18n} = useTranslation();
  const { mutate } = useUpdateStore();
  const EngLang = i18n.language === "en";

  const { data, isLoading, isFetched } = useStore();
  const dispatch = useDispatch();
  const [online, setOnline] = useState<boolean>(
    data?.data?.openStatus === STORE_STATUS.STORE_OPEN_STATUS ? true : false
  );

  const [showAddStoreForm, setShowAddStoreForm] = useState(false);
  const [showEditStoreForm, setShowEditStoreForm] = useState(false);

  const columns = useMemo(
    () => [
      {
        title: t("prefrences.day"),
        selector: (row: WorkingHours) => row.day,
      },
      {
        title: t("prefrences.operationTimeStart"),
        selector: (row: WorkingHours) => row.operationTimeStart,
      },
      {
        title: t("prefrences.operationTimeEnd"),
        selector: (row: WorkingHours) => row.operationTimeEnd,
      },
    ],
    [t]
  );

  useEffect(() => {
    setOnline(
      data?.data?.openStatus === STORE_STATUS.STORE_OPEN_STATUS ? true : false
    );
    if (isFetched) {
      dispatch(setStore(data));
    }
  }, [data, dispatch, isFetched]);

  const onOpenStatusChange = (event: any) => {
    const formData = new FormData();
    formData.append(
      "openStatus",
      !online
        ? STORE_STATUS.STORE_OPEN_STATUS
        : STORE_STATUS.STORE_CLOSED_STATUS
    );

    return mutate(
      {
        id: data?.data._id,
        body: formData,
      },
      {
        onSuccess: (data) => {
          setOnline(!online);
        },
      }
    );
  };

  if (isLoading) {
    return (
      <Fragment>
        <NavBar title={t("prefrences.title")} />
        <div
          style={{
            alignContent: "center",
            margin: "135px 15px 30px auto",
            position: "relative",
            width: "calc(100% - 280px)",
          }}
        >
          <LoadingSpinner />
        </div>
      </Fragment>
    );
  }

  return (
    <>
      <div className="preferencesContainer">
        <NavBar title={t("prefrences.title")} />
        {!data?.data ? (
          <div className="contentEmpty">
            <EmptyContent
              title={t("prefrences.noStoreCreated")}
              hasButton
              buttonName={t("preferences.createStore")}
              onClick={() => setShowAddStoreForm(true)}
            />
          </div>
        ) : (
          <div className="content">
            <div className="header">
              <div>
                <CgFileDocument size={20} />
                {t("prefrences.details")}
              </div>
              <div>
                {online
                  ? t("prefrences.storeOpen")
                  : t("prefrences.storeClosed")}
                <label className="switch">
                  <input type="checkbox" checked={online} />
                  <span className="slider round" onClick={onOpenStatusChange} />
                </label>
                <FiEdit
                  size={20}
                  onClick={() => {
                    setShowEditStoreForm(true);
                  }}
                />
              </div>
            </div>

            <div className="container">
              <div>
                <div className="imgContainer">
                  <img
                    src={data?.data.image}
                    alt=""
                    style={{
                      borderRadius: 8,
                    }}
                  />
                  </div>
                  
                <div>
                    <div>
                      
                    <div>
                      <div className="title">{t("prefrences.storeName")}</div>
                      <div className="body">{data?.data.name}</div>
                      </div>
                    </div>
                    
                  <hr />
                  <div>
                    <div className="title">{t("prefrences.description")}</div>
                    <div className="body">{data?.data.description}</div>
                    </div>                        
                    {(EngLang ? data?.data?.category?.name !== "Food" : data?.data?.category?.name !== "الطعام") && (
                      <>
                          <hr />
                       <div>
                    <div className="title">{t("Stock")}</div>
                    <div className="body">{data?.data.isUseStock===true?t("Use stock"):t("Not use stock")}</div>
                  </div> 
                      </>

                      )}


                  </div>
                  
                </div>
                
              <div>
                <div className="header">{t("prefrences.workingHours")}</div>
                <div className="container">
                  <div className="tableContainer" id="tableContainer">
                    <Table
                      columns={columns}
                      data={data!.data!.workingHours.map((item) => {
                        return {
                          day: numberToDay(item.day),
                          operationTimeStart: item.operationTimeStart,
                          operationTimeEnd: item.operationTimeEnd,
                        };
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <FloatingForm
        visible={showAddStoreForm}
        setVisible={() => setShowAddStoreForm(false)}
        title={t("store.CreateStore")}
        body={
          <AddStoreForm
            closeModal={() => {
              setShowAddStoreForm(false);
            }}
          />
        }
      />
      <FloatingForm
        visible={showEditStoreForm}
        setVisible={() => setShowEditStoreForm(false)}
        title={t("store.EditStore")}
        body={
          <EditStoreForm
            closeModal={() => {
              setShowEditStoreForm(false);
            }}
          />
        }
      />
    </>
  );
}

export default Preferences;
