import {
  Box,
  FormHelperText,
  IconButton,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, FieldError } from "react-hook-form";
import ImageUploading, { ImageListType } from "react-images-uploading";
import DeleteIcon from "@mui/icons-material/Delete";

import "./upload-images-preview.component.scss";
import { useTranslation } from "react-i18next";

interface Props extends React.HTMLProps<HTMLDivElement> {
  name: string;
  label: string;
  control: any;
  maxNumber?: number;
  isMultiple?: boolean;
  rules?: { [key: string]: any };
  error?: FieldError;
  errors?:any
}

const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];

export const UploadImagesPreview: React.FC<Props> = ({
  name,
  label,
  control,
  maxNumber,
  isMultiple = false,
  rules = {},
  error,
  errors
}) => {
  const { t } = useTranslation();
  const [invalidImages, setInvalidImages] = useState<string[]>([]);

  const validateImages = (files: ImageListType) => {
    if (!files || files.length === 0) return true; 
    const isValid = files.every((file) =>
      allowedFormats.includes(file.file?.type || "")
    );
    return isValid || t("errors.invalidImageFormat");
  };

  const handleBeforeUpload = (fileList: ImageListType) => {
    const validFiles = fileList.filter((file) =>
      allowedFormats.includes(file.file?.type || "")
    );

    const invalidFiles = fileList.filter(
      (file) => !allowedFormats.includes(file.file?.type || "")
    );

    setInvalidImages(invalidFiles.map((file) => file.file?.name || ""));

    return validFiles;
  };
const getNestedError = (obj:any, path:any) => {
  return path.split('.').reduce((o:any, key:any) => o?.[key], obj);
};
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...rules,
        validate: (value) => validateImages(value), // Ensure valid files
      }}
      render={({ field }) => {
        const { ref, ...restField } = field;
        return (
          <>
            <ImageUploading
              {...restField}
              multiple={isMultiple}
              maxNumber={maxNumber}
              onChange={(files) => {
                const validFiles = handleBeforeUpload(files);
                restField.onChange(validFiles);
              }}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <div className="upload__image-wrapper">
                  <button
                    type="button"
                    className={`upload__image-dropzone ${
                      !!error ? "upload--error__image-dropzone" : ""
                    }`}
                    style={
                      isDragging
                        ? {
                            color: "red",
                          }
                        : undefined
                    }
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <Box textAlign={"center"}>
                      <div className="upload__image-dropzone-btn">
                        {t("store.AddFiles")}
                      </div>
                      <Typography
                        sx={{ color: "#6D7175", my: 1 }}
                        className="image-accept"
                      >
                        {t("product.Accepts")} .png, .jpg, .jpeg
                                      <br/>
                               {t("recommendedResolution")}

                      </Typography>
                    </Box>
                  </button>

{errors && getNestedError(errors, name) && (
  <FormHelperText error>{getNestedError(errors, name)?.message}</FormHelperText>
)}
                  {/* {errors && errors[name] && (
                    <FormHelperText error>{errors[name]?.message}</FormHelperText>
                  )} */}

                  {/* Feedback for invalid images */}
                  {invalidImages.length > 0 && (
                    <Typography color="error" variant="body2">
                      {t("errors.invalidImageFormat")}: {invalidImages.join(", ")}
                    </Typography>
                  )}

                  <div className="upload__image-list">
                    {Array.isArray(imageList) &&
                      imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img
                            onClick={() => onImageUpdate(index)}
                            src={
                              typeof image === "string" ? image : image.dataURL
                            }
                            alt=""
                            width="100"
                            style={{
                              borderRadius: "8px",
                              height: "auto",
                              objectFit: "cover",
                            }}
                          />
                          <div className="image-item__btn-wrapper">
                            <div className="image-item__btn-remove">
                              <IconButton
                                type="button"
                                aria-label="delete"
                                onClick={() => onImageRemove(index)}
                              >
                                <DeleteIcon color="warning" />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </ImageUploading>
          </>
        );
      }}
    />
  );
};
