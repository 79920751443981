import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useListEmployees } from "../../@core/hooks/query/employee";
import { Employee, IEmployee } from "../../@core/models";
import { useCurrentMerchantSelector } from "../../@core/slice";
import {
  FilterHeader,
  FloatingForm,
  NavBar,
  NoData,
  Table,
} from "../../components";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { AddEmployeeForm } from "./components";

//Icons
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { ViewEmployee } from "./components/view-employee.component";
import { EditEmployeeForm } from "./components/edit-employee-form";
import { BiTrash } from "react-icons/bi";
import { toast } from "react-toastify";
import { ConfirmModal } from "../../components/confirm-modal/confirm-modal.component";
import { useDeleteEmployee } from "../../@core/hooks/mutation/employee/use-delete-employee";
import { useQueryClient } from "react-query";

function Employees() {
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { merchant } = useCurrentMerchantSelector();
  const [formVisible, setFormVisible] = useState(false);
  const [formVisibleEdit, setFormVisibleEdit] = useState(false);
  const [viewEmployeeDetails, setViewEmployeeDetails] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const isRtl = i18n.dir() === "rtl";
  const openAddModalHandler = () => {
    setFormVisible(true);
  };
  const {
    data: employees,
    isLoading: isEmployeesLoading,
    refetch: Refetch,
  } = useListEmployees({
    storeId: merchant?._id ?? "",
    limit: 10,
    page,
    search: search,
  });
  useEffect(() => {
    Refetch();
  }, [Refetch, page, search]);

  const { mutate: deleteEmp, isLoading: isDeleting } = useDeleteEmployee();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<any>(null);
  const openConfirmDelete = (selectedItem: any) => {
    setShowConfirmModal(true);
    setSelectedItem(selectedItem);
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name === "search") setSearch(value);
  };
  const onDelete = (id: string) => {
    deleteEmp(
      {
        id,
        storeId: merchant?._id,
      },
      {
        onSuccess: () => {
          toast.success(t("success.successfullyDelated"));
          queryClient.invalidateQueries("employees");
          setSelectedItem(null);
          setShowConfirmModal(false);
        },
        onError(error) {
          toast.error(t("errors.faildDelated"));
          setShowConfirmModal(false);
        },
      }
    );
  };
  const columns = useMemo(
    () => [
      {
        title: t("employee.name"),
        selector: (row: IEmployee) => row.name,
      },
      {
        title: t("employee.email"),
        selector: (row: IEmployee) => row.email,
      },
      {
        title: t("employee.phone"),
        selector: (row: IEmployee) =>{
          return <span> {row.countryCode}  {row.phoneNumber}</span>
        },
      },
      {
        title: t("employee.job"),
        selector: (row: IEmployee) => row.job,
      },
      {
        title: t("employee.action"),
        selector: (row: IEmployee) => (
          <div className={isRtl ? "flexActionsRtl" : "flexActions"}>
            <AiOutlineEye
              size={20}
              onClick={() => {
                setSelectedEmployee(row as any);
                setViewEmployeeDetails(true);
              }}
            />
            <FiEdit
              size={20}
              onClick={() => {
                setFormVisibleEdit(true);
                setSelectedEmployee(row as any);
              }}
            />
            <BiTrash
              size={20}
              onClick={() => {
                openConfirmDelete(row as any);
              }}
            />
          </div>
        ),
      },
    ],
    [isRtl, t]
  );
  const EmptyData = (
    <NoData
      title={t("emp.titleNoData")}
      description={t("emp.DescripNoData")}
      buttonText={t("emp.AddEmployee")}
      buttonClickHandler={openAddModalHandler}
    />
  );
  if (isEmployeesLoading)
    return (
      <Fragment>
        <NavBar title={t("menuNavigation.employees")} />
        <FilterHeader />
        <div className="tableContainer" id="tableContainer">
          <Table columns={columns} data={[]}>
            <LoadingSpinner />
          </Table>
        </div>
      </Fragment>
    );

  return (
    <div>
      <NavBar title={t("menuNavigation.employees")} />
      <FilterHeader
        // Search
        searchName="search"
        searchPlaceHolder={t("Search")}
        searchValue={search}
        searchOnChange={handleChange}
        hasAdd
        onAdd={() => {
          setFormVisible(true);
        }}
      />
      <div>
        {Array.isArray(employees?.data) && employees?.data.length === 0 ? (
          EmptyData
        ) : (
          <div className="tableContainer" id="tableContainer">
            <Table
              columns={columns}
              data={employees?.data ?? []}
              handlePageChange={(selectedItem) =>
                setPage(selectedItem.selected)
              }
              pageCount={employees?.numberOfPages ?? 0}
              initialPage={page}
            />
          </div>
        )}
      </div>
      <FloatingForm
        visible={formVisible}
        setVisible={() => setFormVisible(false)}
        title={t("emp.AddEmployee")}
        body={<AddEmployeeForm closeModal={() => setFormVisible(false)} />}
        btnName={t("emp.Save")}
      />
      <FloatingForm
        visible={formVisibleEdit}
        setVisible={() => setFormVisibleEdit(false)}
        title={t("emp.EditEmployee")}
        body={
          <EditEmployeeForm
            closeModal={() => setFormVisibleEdit(false)}
            selectedEmployee={selectedEmployee}
          />
        }
        btnName={t("emp.Save")}
      />
      <FloatingForm
        visible={viewEmployeeDetails}
        setVisible={() => setViewEmployeeDetails(false)}
        title={t("emp.EmployeeDetails")}
        body={
          <ViewEmployee
            selectedEmployee={selectedEmployee}
            closeModal={() => {
              setSelectedEmployee(undefined);
              setViewEmployeeDetails(false);
            }}
          />
        }
      />
      <ConfirmModal
        title={t("emp.DeleteEmployee")}
        description={t("emp.DescriptionDelet")}
        isOpen={showConfirmModal}
        handleConfirm={() => onDelete(selectedItem._id)}
        handleClose={() => setShowConfirmModal(false)}
        isLoading={isDeleting}
      />
    </div>
  );
}

export default Employees;
