import { InputLabel, TextField } from "@mui/material";
import React from "react";
import { Controller, FieldError } from "react-hook-form";
import "./style.scss";
interface Props {
  name: string;
  inputId: string;
  label: string;
  placeholder?: string;
  control: any;
  type?: string;
  rules?: any;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  error?: FieldError| undefined|any;
  maxRows?:number;
  inputProps?:
    | "url"
    | "search"
    | "text"
    | "none"
    | "tel"
    | "email"
    | "numeric"
    | "decimal"
    | undefined;
}

export const TextInput: React.FC<Props> = ({
  inputId,
  name,
  label,
  placeholder = label,
  type = "text",
  inputProps,
  control,
  rules = {},
  multiline = false,
  disabled = false,
  error,
  rows,
  maxRows,
}) => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => (
        <>
          <InputLabel
            sx={{ textAlign: "initial !important" }}
            className="label"
            htmlFor={field.name}
          >
            {label}
          </InputLabel>
          <TextField
            {...field}
            type={type}
            fullWidth
            id={`${inputId}-category`}
            margin="normal"
            // label={label}
            placeholder={placeholder}
            disabled={disabled}
            size="small"
            className="textField"
            multiline={multiline}
            rows={rows}
            maxRows={maxRows}
            error={!!error}
            helperText={error?.message}
            inputProps={{ inputMode: inputProps ? inputProps : "none" }}
          />
        </>
      )}
    />
  );
};
