import { ListProductsResponse } from "../../../models";
import { useCustomQuery } from "../use-query.hook";

interface Props {
  storeId: string;
  page?: number;
  limit?: number;
  paginated?: boolean;
}

export const useListProducts = ({
  storeId,
  page = 1,
  limit = 10,
  paginated = true,
}: Props) =>
  useCustomQuery<ListProductsResponse>({
    url: `${storeId}/product`,
    page,
    limit,
    paginated,
    dependedKey: "products",
    options: {
    keepPreviousData: false,
    cacheTime: 0,
    },
  });

export const useListallProducts = ({
  storeId,
  paginated = false,
}: Props) =>
  useCustomQuery<ListProductsResponse>({
    url: `${storeId}/product`,
    paginated,
  });
