import { Box, Button, Paper, Stack } from "@mui/material";
import React, { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NavBar,
  SubNavBar,
  MediaCardList,
  MediaCard,
  FloatingForm,
  NoData,
} from "../../components";
import { AddSizesForm } from "./add-size-form.component";
import { useSizesQuery } from "../../@core/hooks/query/size/size.hook";
import {
  useAddSizes,
  useDeleteSize,
  useEditSize,
} from "../../@core/hooks/mutation/sizes/add-size.mutate";
import { useQueryClient } from "react-query";
import { ConfirmModal } from "../../components/confirm-modal/confirm-modal.component";
import { Pagination } from "../../components/custom-pagination/customPagination";
import { Size } from "../../@core/api/sizes/sizes.api";
import { SelectChangeEvent } from "@mui/material/Select";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import "./style.scss";

interface Props extends React.HTMLProps<HTMLDivElement> {}

export const SizesPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState<number | undefined>(5);

  const skip = limit ? page * limit : undefined;
  const {
    data: sizesData,
    isLoading: loadingSize,
    error,
  } = useSizesQuery({ skip, limit });

  const { mutate: addSize, isLoading } = useAddSizes();
  const { mutate: deleteSize, isLoading: isDeleting } = useDeleteSize();
  const { mutate: editSize } = useEditSize();

  const [showModal, setShowModal] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [showErrorModal, setShowErrorModal] = useState(false);

  const errorMessage = useRef("");

  const onSubmitFormHandler = (data: any) => {
    const isEditMode = selectedItem !== null;
    if (isEditMode) {
      editSize(
        { id: selectedItem._id, data },
        {
          onSuccess: () => {
            toast.success(t("success.successfullyUpdated"));
            queryClient.invalidateQueries("sizes");
            setSelectedItem(null);
            setShowModal(false);
          },
        onError: (err:any) => {
          toast.error(err?.response?.data?.message);
        },
        }
      );
    } else {
      addSize(data, {
        onSuccess: () => {
          toast.success(t("success.successfullyAdd"));
          queryClient.invalidateQueries("sizes");
          setSelectedItem(null);
          setShowModal(false);
        },
        onError: (err:any) => {
          toast.error(err?.response?.data?.message);
        },
      });
    }
  };

  const openAddModalHandler = (isEditMode = false) => {
    if (!isEditMode) {
      setSelectedItem(null);
    }

    setShowModal(true);
  };

  const onDelete = (id: string) => {
    deleteSize(id, {
      onSuccess: () => {
        toast.success(t("success.successfullyDelated"));
        queryClient.invalidateQueries("sizes");
        setSelectedItem(null);
        setShowConfirmModal(false);
      },
        onError: (err:any) => {
          toast.error(err?.response?.data?.message);
        },
    });
  };

  const openConfirmDelete = (selectedItem: any) => {
    setShowConfirmModal(true);
    setSelectedItem(selectedItem);
  };

  const openEditForm = (selectedItem: any) => {
    setShowModal(true);
    setSelectedItem(selectedItem);
  };

  const numberOfPages = sizesData?.numberOfPages || 0;
  const numberOfRecords = sizesData?.numberOfRecords || 0;
  const handleLimitChange = (event: SelectChangeEvent<number>) => {
    const newLimit = Number(event.target.value) || undefined;
    setLimit(newLimit);
    setPage(0);
  };
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  const EmptyData = (
    <NoData
      title={t("size.NoSizesfound")}
      description={t("size.DescriptionNoFound")}
      buttonText={t("size.AddSize")}
      buttonClickHandler={openAddModalHandler}
    />
  );
  if (loadingSize) {
    return (
      <Fragment>
        <NavBar title={t("menuNavigation.sizes")} />
        <SubNavBar>
          <Button
            onClick={() => openAddModalHandler()}
            variant="contained"
            size="large"
            className="buttonHandleSubmit"
            disableElevation
          >
            {t("menuNavigation.addSizes")}
          </Button>
        </SubNavBar>
        <section className="page-container">
          <LoadingSpinner />
        </section>
      </Fragment>
    );
  }

  if (error) {
    toast.error(error?.message);
  }
  return (
    <>
      <NavBar title={t("menuNavigation.sizes")} />

      <SubNavBar>
        <Button
          onClick={() => openAddModalHandler()}
          variant="contained"
          size="large"
          className="buttonHandleSubmit"
          disableElevation
        >
          {t("menuNavigation.addSizes")}
        </Button>
      </SubNavBar>
      {sizesData?.data?.length === 0 ? (
        EmptyData
      ) : (
        <>
          <section className="page-container">
            <Paper elevation={3} sx={{ padding: "1rem" }}>
              <>
                <MediaCardList>
                  {sizesData?.data?.map((size: Size) => (
                    <div key={size._id}>
                      <MediaCard
                        title={size.size}
                        subCategory={size.subCategory.name}
                        onDelete={() => openConfirmDelete(size)}
                        onEdit={() => openEditForm(size)}
                      />
                    </div>
                  ))}
                </MediaCardList>
              </>
            </Paper>
            <Paper
              elevation={1}
              sx={{
                position: "fixed",
                bottom: 20,
                left: "50%",
                transform: "translateX(-50%)",
                padding: "1rem",
                minWidth: "md",
                textAlign: "center",
              }}
            >
              <Pagination
                currentPage={page}
                numberOfPages={numberOfPages}
                onPageChange={handlePageChange}
                totalRecords={numberOfRecords}
                limit={limit!}
                onLimitChange={handleLimitChange}
              />
            </Paper>
          </section>
        </>
      )}

      <FloatingForm
        visible={showModal}
        setVisible={() => setShowModal(false)}
        title={selectedItem ? t("size.editSize") : t("size.AddSize")}
        btnName={selectedItem ? t("size.editSize") : t("size.AddSize")}
        body={
          <AddSizesForm
            submitFormHandler={onSubmitFormHandler}
            closeModal={() => setShowModal(false)}
            isSubmitting={isLoading}
            selectedItem={selectedItem}
          />
        }
      />

      <ConfirmModal
        title={t("size.DeleteSize")}
        description={t("size.DescriptionDelet")}
        isOpen={showConfirmModal}
        handleConfirm={() => onDelete(selectedItem._id)}
        handleClose={() => setShowConfirmModal(false)}
        isLoading={isDeleting}
      />

      <ConfirmModal
        title={t("size.DeletErr")}
        description={errorMessage.current}
        isOpen={showErrorModal}
        handleClose={() => {
          setShowErrorModal(false);
          errorMessage.current = "";
        }}
        isLoading={false}
      />
    </>
  );
};
