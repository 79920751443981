import {
  Box,
  Button,
  Chip,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { FileUploader } from "react-drag-drop-files";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { CgFileDocument } from "react-icons/cg";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useListColor,
  useListSize,
  useProduct,
  useSubcategories,
  useUpdateProduct,
} from "../../@core/hooks";
import {
  Color,
  Size,
  StockProduct,
  StockProductFormData,
} from "../../@core/models";
import { useCurrentMerchantSelector } from "../../@core/slice";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import NavBar from "../NavBar";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { TextInput } from "../custom-inputs";
import { useTagByCategoryIdQuery } from "../../@core/hooks/query/tag/tags.hook";
import { useQueryClient } from "react-query";
import { ImageUploaderMultiple } from "./multiImageUploader";
import { HiPlus } from "react-icons/hi";
import { ImageUploader } from "./imageUploader";
import { Delete, FileCopy } from "@mui/icons-material";
import { useEffect } from "react";

interface Props extends React.HTMLProps<HTMLDivElement> {}

export const EditStockProductForm: React.FC<Props> = () => {
  const navigte = useNavigate();
  const { merchant } = useCurrentMerchantSelector();
  const { productId } = useParams();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const EngLang = i18n.language === "en";

  const { data, isLoading: isProductLoading } = useProduct({
    productId: productId as string,
    storeId: merchant!!._id,
  });

  const product = data?.data as StockProduct;
  const { data: tags, isLoading: isTagsLoading } = useTagByCategoryIdQuery({
    categoryId: merchant?.category?._id||"",
  });
  const { data: colors, isLoading: isColorsLoading } = useListColor();
  const { data: sizes, isLoading: isSizesLoading } = useListSize();
  const { data: subCategories, isLoading: isSubcategoryLoading } =
    useSubcategories(merchant?.category?._id||"");
  const { mutate: updateProduct } = useUpdateProduct();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<StockProductFormData>({
    mode:"all",
    defaultValues: {
      nameEn: EngLang ? product?.name : product?.translation.name,
      nameAr: EngLang ? product?.translation?.name : product?.name,
      descriptionAr: EngLang
        ? product?.translation?.description
        : product?.description,
      descriptionEn: EngLang
        ? product?.description
        : product?.translation?.description,
      tags: product?.tags?.map((tag) => tag._id) || [],
      subCategory:
        typeof product?.subCategory === "string"
          ? product.subCategory
          : product?.subCategory?._id || "",
      sku: product?.sku,
      mainImage: product?.mainImage,
      price: product?.price,
      shippingPrice: product?.shippingPrice,
      stock: product?.stock || 0,
      // variants:
      //   product?.variants?.map((variant) => ({
      //     _id: variant._id,
      //     color: (variant.color as Color).id ?? "",
      //     size: (variant.size as Size).id ?? "",
      //     price: variant.price ?? 0,
      //     stock: variant.stock ?? 0,
      //     images: variant.images ?? [],
      //   })) || [],
      variants:
      product?.variants
        ?.map((variant) => ({
          _id: variant._id,
          color: (variant.color as Color).id ?? "",
          size: (variant.size as Size).id ?? "",
          price: variant.price ?? 0,
          stock: variant.stock ?? 0,
          images: variant.images ?? [],
        }))
        .sort((a, b) => {
          // Sorting based on color and name (if you want a specific sorting order)
          const colorCompare = a.color.localeCompare(b.color);
          if (colorCompare !== 0) return colorCompare;
          return a.size.localeCompare(b.size); // Example: sorting by size if colors match
        }) || [],
      currency: product?.currency,
    },
  });
  useEffect(() => {
  if (product?.variants) {
    const sortedVariants = product.variants
      .map((variant) => ({
        _id: variant._id,
          color: (variant.color as Color).id ?? "",
          size: (variant.size as Size).id ?? "",
        price: variant.price ?? 0,
        stock: variant.stock ?? 0,
        images: variant.images ?? [],
      }))
      .sort((a, b) => {
        const colorCompare = a.color.localeCompare(b.color);
        if (colorCompare !== 0) return colorCompare;
        return a.size.localeCompare(b.size); 
      });

    setValue("variants", sortedVariants); 
  }
  }, [product?.variants, setValue]);
  
  const { fields, append, remove } = useFieldArray({
    control,
    name: "variants" as never,
  });

  const onSubmit = (values: StockProductFormData) => {
  const formData = new FormData();

  let variantsData = values?.variants?.map(
    (
      variant: {
        images?: (File | string)[];
        color?: string;
        size?: string;
        price?: number;
        stock?: number;
        _id?: string;
      },
      index: number
    ) => {
      let { images, ...rest } = variant;
      let variantData: {
        color?: string;
        size?: string;
        price?: number;
        stock?: number;
        _id?: string;
        images?: string[];
      } = { ...rest };
      variantData.images = [];

      if (images && images.length > 0) {
        images.forEach((image, imageIndex) => {
          if (image instanceof File) {
            formData.append(
              `variants[${index}].images[${imageIndex}]`,
              image
            );
          } else {
            variantData?.images?.push(image);
          }
        });
      }

      return variantData;
    }
  );

  formData.append("variants", JSON.stringify(variantsData));

  Object.entries(values).forEach(async ([key, value]) => {
    if (key === "variants") {
      return;
    }
    if (key === "tags") {
      formData.append("tags", JSON.stringify(values.tags || []));
      return;
    }
    if (
      key === "subCategory" &&
      (value === "" || value === null || value === undefined)
    ) {
      return;
    }
    formData.append(key, value);
  });
  updateProduct(
    {
      storeId: merchant?._id!,
      productId,
      body: formData,
    },
    {
      onSuccess: () => {
        toast.success(t("product.updateSuccess"));
        queryClient.invalidateQueries("product");
        navigte(`/products/${productId}`);
      },
      onError: () => {
        toast.error(t("product.updateFaild"));
      },
    }
  );
};

  if (
    isSubcategoryLoading ||
    isTagsLoading ||
    isColorsLoading ||
    isSizesLoading
  ) {
    return (
      <div
        style={{
          width: "calc(100% - 300px)",
          margin: "160px 15px 30px auto",
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }
  if (isProductLoading) {
    return (
      <div className="content">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="stock">
      <NavBar
        title={t("product.EditProduct")}
        status={product?.status}
        hasBack={true}
      />

      <div className="editProductAction">
        <div className="routes">
          <span onClick={() => navigte("/products")}>
            {t("product.Products")}
          </span>
          <IoIosArrowForward fontWeight={900} size={20} />
          <span>
            {t("product.Editing")}
            <span
              style={{ color: "sandybrown", margin: "0px 10px" }}
              onClick={() => navigte(`/products/${productId}`)}
            >
              {product?.name}
            </span>
          </span>
        </div>
      </div>

      <div className="content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Box>
              <BasicDetailsForm
                control={control}
                errors={errors}
                t={t}
                subcategories={subCategories?.data}
                tags={tags?.data}
              />
              <br />
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{
                  xs: 1,
                  sm: 2,
                  md: 3,
                }}
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  marginTop: "20px",
                }}
              >
                <Grid item className="header" md={12} xs={12}>
                  <CgFileDocument />
                  {t("product.ProductImage")}
                </Grid>

                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={2}
                    paddingRight={3}
                    paddingLeft={3}
                    marginTop={2}
                    marginBottom={2}
                  >
                    {/* Image */}
                    <Grid item xs={12}>
                    <Controller
                        name="mainImage"
                        control={control}
                        rules={{
                            required: {
                             value: true,
                             message: t("product.Required"),
                                },
                           }}
                           render={({ field: { onChange, value } }) => (
                          <ImageUploader onChange={onChange}
                            value={value}
                            error={errors?.mainImage}  
                            hight="auto" 
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <br />

              <Grid
                container
                rowSpacing={3}
                columnSpacing={{
                  xs: 1,
                  sm: 2,
                  md: 3,
                }}
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  marginTop: "20px",
                }}>
                <Grid item className="header" md={12} xs={12}>
                  <CgFileDocument />
                  {t("product.PriceShipping")}
                </Grid>

                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={2}
                    paddingRight={3}
                    paddingLeft={3}
                    marginTop={2}
                    marginBottom={2}>
                    <Grid item xs={12} md={4}>
                      {/* Product Price */}
                      <TextInput
                        type="number"
                        inputId="price"
                        name="price"
                        label={t("product.Price")}
                        rules={{
                          required: {
                            value: true,
                            message: t("product.PriceRequired"),
                          },
                        }}
                        control={control}
                        error={errors.price}
                        inputProps="numeric"
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      {/* Shipping Price */}
                      <TextInput
                        type="number"
                        inputId="shippingPrice"
                        name="shippingPrice"
                        label={t("product.PriceShipping")}
                        rules={{
                          required: {
                            value: true,
                            message: t("product.ShippingPriceRequired"),
                          },
                        }}
                        control={control}
                        error={errors.shippingPrice}
                        inputProps="numeric"
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      {/* Stock */}
                      <TextInput
                        type="number"
                        inputId="stock"
                        name="stock"
                        label={t("product.Stock")}
                        control={control}
                        error={errors.stock}
                        inputProps="numeric"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <br />
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{
                  xs: 1,
                  sm: 2,
                  md: 3,
                }}
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  marginTop: "20px",
                }}>
                <Grid item className="header" md={12} xs={12}>
                  <CgFileDocument />
                  {t("product.Tags")}
                </Grid>

                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={2}
                    paddingRight={3}
                    paddingLeft={3}
                    marginTop={2}
                    marginBottom={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Controller
                          name="tags"
                          control={control}
                          render={({ field }) => (
                            <Select
                              size="small"
                              sx={{
                                borderRadius: "12px",
                              }}
                              placeholder={t("product.Tags")}
                              displayEmpty
                              id="tags-type-select"
                              labelId="tags-type-select-label"
                              error={!!errors.tags}
                              multiple
                              value={field.value || []}
                              onChange={field.onChange}
                              onBlur={field.onBlur}
                              inputRef={field.ref}
                              renderValue={(selected) => {
                                if (
                                  !selected ||
                                  (Array.isArray(selected) &&
                                    selected?.length === 0)
                                ) {
                                  return (
                                    <span
                                      style={{
                                        color: "#aaa",
                                        fontSize: "14px",
                                      }}>
                                      {t("product.Tags")}
                                    </span>
                                  );
                                }

                                const selectedOptions = tags?.data?.filter(
                                  (option: any) =>
                                    selected?.includes(option._id)
                                );

                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 4,
                                    }}>
                                    {selectedOptions?.map((option: any) => (
                                      <Chip
                                        key={option?._id}
                                        label={option?.name}
                                      />
                                    ))}
                                  </div>
                                );
                              }}>
                              {tags?.data?.map((tag: any) => (
                                <MenuItem key={tag?._id} value={tag?._id}>
                                  {tag?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <br />

            <Grid
              container
              rowSpacing={3}
              columnSpacing={{
                xs: 1,
                sm: 2,
                md: 3,
              }}
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                marginTop: "20px",
              }}>
              <Grid item className="header2" md={12} xs={12}>
                <Box width={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                  <Box px={2} style={{display:"flex",gap:"10px"}}>
                   <CgFileDocument />
                  {t("product.Variants")}
                 </Box>
                  
                <Box  display="flex" justifyContent="flex-end" gap={2}>
                <Button
                   onClick={() => {
                    append({
                      color: "",
                      size: "",
                      images: [],
                      price: 0,
                      stock: 0,
                    });
                  }}
                  variant="contained"
                  className="buttonHandleSubmit"
                  disableElevation
                >
                   {t("product.AddVariant")}
                </Button>
              </Box>
                </Box>
              </Grid>

              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={2}
                  paddingRight={3}
                  paddingLeft={3}
                  marginTop={2}
                  marginBottom={2}
                >
                  {fields?.map(({ id }, index) => {
                    return (
                     <Paper key={id} sx={{ width: "100%",px:2,pb:2, pt: 0.2, my: 2,borderRadius:"8px" }} elevation={2} >
                        <Grid
                         container
                          columnSpacing={2}
                          key={id}
                        >
                          
                       <Grid item xs={12} md={6} >
                       <Grid
                         container
                              spacing={2}
                              pt={2.5}
                             key={index}
                            >
                           <Grid item xs={12} md={6} >
                          <Controller
                            control={control}
                            // @ts-ignore
                            name={`variants[${index}].color`}
                            rules={{
                              required: {
                                value: true,
                                message: t("product.ColorRequired"),
                              },
                            }}
                            render={({ field }) => (
                              <>
                                <InputLabel
                                  sx={{ textAlign: "initial !important" }}
                                  className="label"
                                  id="variants">
                                  {`${t("product.details.color")} - ${
                                    index + 1
                                  }`}
                                </InputLabel>
                                <FormControl
                                  sx={{
                                    width: "100%",
                                  }}
                                  error={!!errors?.variants?.[index]?.color}
                                  size="small">
                                  <Select
                                    fullWidth
                                    sx={{
                                      borderRadius: "12px",
                                    }}
                                    id="variants"
                                    size="small"
                                    displayEmpty
                                    placeholder="Color"
                                    error={!!errors?.variants?.[index]?.color}
                                    {...field}
                                    renderValue={(selected) => {
                                      if (
                                        !selected ||
                                        (Array.isArray(selected) &&
                                          selected.length === 0)
                                      ) {
                                        return (
                                          <span
                                            style={{
                                              color: "#aaa",
                                              fontSize: "14px",
                                            }}>
                                            {t("app.Select-Color")}
                                          </span>
                                        );
                                      }
                                      const selectedColor = colors?.data?.find(
                                        (color) => color._id === selected
                                      );
                                      return selectedColor
                                        ? selectedColor.name
                                        : "";
                                    }}>
                                    {[
                                      colors?.data?.map((color: any) => (
                                        <MenuItem
                                          key={color._id}
                                          value={color._id}>
                                          {color.name}
                                        </MenuItem>
                                      )),
                                    ]}
                                  </Select>
                                  <FormHelperText error={!!errors.variants}>
                                    {errors.variants?.message}
                                  </FormHelperText>
                                </FormControl>
                              </>
                            )}
                          />
                           </Grid>

                           <Grid item xs={12} md={6} >
                          {/* Size */}
                          <Controller
                            control={control}
                            // @ts-ignore
                            name={`variants[${index}].size`}
                            rules={{
                              required: {
                                value: true,
                                message: t("product.SizeRequired"),
                              },
                            }}
                            render={({ field }) => (
                              <>
                                <InputLabel
                                  sx={{ textAlign: "initial !important" }}
                                  className="label"
                                  id="variants">
                                  {`${t("product.Size")} - ${index + 1}`}
                                </InputLabel>
                                <FormControl
                                  sx={{
                                    width: "100%",
                                  }}
                                  error={!!errors?.variants?.[index]?.size}
                                  size="small">
                                  <Select
                                    fullWidth
                                    sx={{
                                      borderRadius: "12px",
                                    }}
                                    id="variants"
                                    size="small"
                                    placeholder={t("product.Size")}
                                    displayEmpty
                                    error={!!errors?.variants?.[index]?.size}
                                    {...field}
                                    renderValue={(selected) => {
                                      if (
                                        !selected ||
                                        (Array.isArray(selected) &&
                                          selected.length === 0)
                                      ) {
                                        return (
                                          <span
                                            style={{
                                              color: "#aaa",
                                              fontSize: "14px",
                                            }}>
                                            {t("app.Select-Size")}
                                          </span>
                                        );
                                      }
                                      const selectedSize = sizes?.data?.find(
                                        (size) => size._id === selected
                                      );
                                      return selectedSize
                                        ? selectedSize.size
                                        : "";
                                    }}>
                                    {[
                                      sizes?.data?.map((size: any) => (
                                        <MenuItem
                                          key={size._id}
                                          value={size._id}>
                                          {size.size}
                                        </MenuItem>
                                      )),
                                    ]}
                                  </Select>
                                  <FormHelperText error={!!errors.variants}>
                                    {errors.variants?.message}
                                  </FormHelperText>
                                </FormControl>
                              </>
                            )}
                          />
                        </Grid>
                               
                        <Grid item xs={12} md={6} >
                          {/* Variant Price */}
                          <Controller
                            // @ts-ignore
                            name={`variants[${index}].price`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: t("product.PriceRequired"),
                              },
                            }}
                            render={({ field }) => (
                              <>
                                <InputLabel
                                  sx={{ textAlign: "initial !important" }}
                                  className="label"
                                  htmlFor={field.name}>
                                  {t("product.Price")}
                                </InputLabel>
                                <TextField
                                  size="small"
                                  {...field}
                                  fullWidth
                                  inputProps={{
                                    inputMode: "numeric",
                                  }}
                                  type="number"
                                  className="textField"
                                  error={!!errors.variants}
                                />
                              </>
                            )}
                          />
                          <FormHelperText error={!!errors.variants}>
                            {errors.variants?.message}
                          </FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={6} >
                          {/* Variant Stock */}

                          <Controller
                            // @ts-ignore
                            name={`variants[${index}].stock`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: t("product.VariantRequired"),
                              },
                            }}
                            render={({ field }) => (
                              <>
                                <InputLabel
                                  className="label"
                                  htmlFor={field.name}
                                  sx={{ textAlign: "initial !important" }}>
                                  {t("product.VariantStock")}
                                </InputLabel>
                                <TextField
                                  size="small"
                                  {...field}
                                  fullWidth
                                  inputProps={{
                                    inputMode: "numeric",
                                  }}
                                  type="number"
                                  className="textField"
                                  error={!!errors.variants}
                                />
                              </>
                            )}
                          />
                          <FormHelperText error={!!errors.variants}>
                            {errors.variants?.message}
                          </FormHelperText>
                          </Grid>

                              {/* </Grid> */}
 
                        </Grid>
                          
                        </Grid>
                        
                          <Grid item xs={12} md={6} >
                             <Box py={0.5}  display={"flex"} gap={0.5} justifyContent={"end"} alignItems={"center"}>
                              <Tooltip title="Duplicate Variant">
                                
                               <IconButton
                                 onClick={() => {
                                const currentVariants = getValues("variants");
                                const variantToCopy = currentVariants?.[index];
                              if (variantToCopy) {
                              append({
                              color: variantToCopy.color || "",
                              size: variantToCopy.size || "",
                              images: [...variantToCopy.images],
                              price: variantToCopy.price || 0,
                              stock: variantToCopy.stock || 0,
                               });
                                }
                             }}
                                 >
                              <FileCopy color="primary" sx={{fontSize:"20px"}}  />

                           </IconButton>
                         </Tooltip>
                              <Tooltip title="Delete Varient">
                                <IconButton
                                 onClick={() => {
                                remove(index); 
                                const updatedVariants = getValues("variants");
                                setValue("variants", updatedVariants);
                              }}
                               >
                             <Delete color="error" sx={{fontSize:"20px"}} />
                            </IconButton>
                              </Tooltip>


                            </Box>
                        <>
                          <Controller
                            name={`variants.${index}.images`}
                            control={control}
                            defaultValue={[]}
                            render={({ field: { onChange, value } }) => (
                              <ImageUploaderMultiple
                                onChange={onChange}
                                value={value}
                              />
                            )}
                          />
                        </>
                        </Grid>

                        </Grid>

                      </Paper>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>

              {/* Cancel and Sumbit */}
              <Grid item xs={12} padding={1} margin={1}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap={2}>
                  <Button
                    type="button"
                    className="formButton"
                    size="large"
                    variant="outlined"
                    color="error"
                    onClick={() => navigte(`/products/${productId}`)}>
                    {t("product.Cancel")}
                  </Button>

                  <Button
                    type="submit"
                    className="buttonHandleSubmit"
                    size="large"
                    variant="contained"
                    color="basePrimary">
                    {t("product.UpdateProduct")}
                  </Button>
                </Stack>
              </Grid>
            </Box>
          </div>
        </form>
      </div>
    </div>
  );
};

const BasicDetailsForm = ({
  control,
  categoryName,
  subcategories,
  errors,
  t,
}: any) => {
  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        marginTop: "20px",
      }}
    >
      <Grid item className="header" md={12} xs={12}>
        <CgFileDocument />
        {t("product.BasicDetails")}
      </Grid>

      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          paddingRight={3}
          paddingLeft={3}
          marginTop={2}
          marginBottom={2}
        >
          {/* Subcategory */}
          <Grid item xs={12} md={4}>
            <InputLabel
              sx={{ textAlign: "initial !important" }}
              className="label"
              id="subCategory-type-select-label"
            >
              {t("product.Subcategory")}
            </InputLabel>
            <FormControl fullWidth>
              <Controller
                name="subCategory"
                control={control}
                render={({ field }) => (
                  <Select
                    size="small"
                    sx={{
                      borderRadius: "12px",
                    }}
                    placeholder={t("product.Subcategory")}
                    id="subCategory-type-select"
                    labelId="subCategory-type-select-label"
                    error={!!errors.subCategory}
                    {...field}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return (
                          <span style={{ color: "#aaa", fontSize: "14px" }}>
                            {t("product.Subcategory")}
                          </span>
                        );
                      }
                      const selectedOption = subcategories?.find(
                        (option: any) => option._id === selected
                      );
                      return selectedOption ? selectedOption.name : "";
                    }}
                  >
                    {[
                      subcategories?.map((subcategory: any) => (
                        <MenuItem key={subcategory._id} value={subcategory._id}>
                          {subcategory.name}
                        </MenuItem>
                      )),
                    ]}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            {/* Name Arabic */}
            <TextInput
              inputId="nameAr"
              name="nameAr"
              label={t("product.ProductNameArabic")}
              rules={{
                required: {
                  value: true,
                  message: t("product.ArabicNamerequired"),
                },
              }}
              control={control}
              error={errors.nameAr}
              inputProps="text"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {/* Name English */}
            <TextInput
              inputId="product-name-en"
              name="nameEn"
              label={t("product.ProductNameEnglish")}
              rules={{
                required: {
                  value: true,
                  message: t("product.EnglishNamerequired"),
                },
              }}
              control={control}
              error={errors.nameEn}
              inputProps="text"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {/* SKU */}
            <TextInput
              inputId="sku"
              name="sku"
              label={t("product.SKU")}
              control={control}
              inputProps="text"
            />
          </Grid>

          <Grid item xs={12} md={12}>
            {/* Description Arabic */}
            <TextInput
              inputId="descriptionAr"
              name="descriptionAr"
              label={t("product.ProductDescriptionArabic")}
              control={control}
              rows={4}
              rules={{
                required: {
                  value: true,
                  message: t("product.ArabicDescriptionRequired"),
                },
              }}
              error={errors.descriptionAr}
              multiline
            />
          </Grid>

          <Grid item xs={12} md={12}>
            {/* Description English */}
            <TextInput
              inputId="descriptionEn"
              name="descriptionEn"
              label={t("product.ProductDescriptionEnglish")}
              control={control}
              rows={4}
              rules={{
                required: {
                  value: true,
                  message: t("product.EnglishDescriptionRequired"),
                },
              }}
              error={errors.descriptionEn}
              multiline
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
