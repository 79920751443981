import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { Controller, FieldError } from "react-hook-form";

interface Props {
  name: string;
  size?: "small" | "medium";
  inputId: string;
  label: string;
  placeholder?: string;
  control: any;
  options?: { label: string; value: string }[];
  rules?: any;
  readonly?: boolean;
  error?: FieldError | null|any;
}

export const SelectInput: React.FC<Props> = ({
  inputId,
  name,
  label,
  placeholder = label,
  options = [],
  control,
  rules = {},
  readonly = false,
  error,
  size = "small",
}) => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => (
        <>
          <InputLabel
            sx={{ textAlign: "initial !important" }}
            className="label"
            id={`${inputId}-label`}
          >
            {label}
          </InputLabel>
          <FormControl
            sx={{
              width: "100%",
            }}
            error={!!error}
            size={size}
          >
            <Select
              sx={{
                borderRadius: "12px",
              }}
              {...field}
              labelId={`${inputId}-label`}
              id={`${inputId}-category`}
              displayEmpty
              readOnly={readonly}
              placeholder={label}
              error={!!error}
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <span style={{ color: "#aaa", fontSize: "14px" }}>
                      {placeholder}
                    </span>
                  );
                }
                const selectedOption = options?.find(
                  (option) => option?.value === selected
                );
                return selectedOption ? selectedOption.label : "";
              }}
            >
              {options?.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>

            {!!error ? <FormHelperText>{error?.message}</FormHelperText> : null}
          </FormControl>
        </>
      )}
    />
  );
};
