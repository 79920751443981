import { Box, Button, FormHelperText, IconButton, Stack, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { FieldError } from "react-hook-form";
import { PlusOne, PlusOneOutlined } from "@mui/icons-material";
import { BiPlusCircle } from "react-icons/bi";
interface Props {
  value: any;
  onChange: any;
  rules?: { [key: string]: any };
  error?: FieldError;
}
const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];


export const ImageUploaderMultiple = ({ onChange, value,error}: Props) => {
  const [previews, setPreviews] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);

  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);


  useEffect(() => {
    if (Array.isArray(value)) {
      const fileReaders = [];
      const previewUrls: string[] = [];

      value.forEach((file) => {
        if (file instanceof Blob) {
          const reader = new FileReader();
          fileReaders.push(reader);
          reader.onloadend = () => {
            previewUrls.push(reader.result as string);
            if (previewUrls.length === value.length) {
              setPreviews(previewUrls);
            }
          };
          reader.readAsDataURL(file);
        } else if (typeof file === "string") {
          previewUrls.push(file);
        }
      });

      if (previewUrls.length === value.length) {
        setPreviews(previewUrls);
      }
    } else {
      setPreviews([]);
    }
  }, [value]);
  const validateFile = (file: File) => {
    if (!allowedFormats.includes(file.type)) {
      setUploadError(t("errors.invalidImageFormat"));
      return false;
    }
    setUploadError(null);
    return true;
  };
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const files = Array.from(e.target.files || []);
  const validFiles = files.filter((file) => validateFile(file));

  if (validFiles.length === 0) {
    return;
  }

  const newFiles = [...value, ...validFiles];
  const newPreviews: string[] = [];

  validFiles.forEach((file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      newPreviews.push(reader.result as string);
      if (newPreviews.length === validFiles.length) {
        setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
      }
    };
    reader.readAsDataURL(file);
  });

  onChange(newFiles);
  if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
};

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
  e.stopPropagation();
  setIsDragging(false);

  const files = Array.from(e.dataTransfer.files || []);
  const validFiles = files.filter((file) => validateFile(file));

  if (validFiles.length === 0) {
    return; 
  }

  const newFiles = [...value, ...validFiles];
  const newPreviews: string[] = [];

  validFiles.forEach((file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      newPreviews.push(reader.result as string);
      if (newPreviews.length === validFiles.length) {
        setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
      }
    };
    reader.readAsDataURL(file);
  });

  onChange(newFiles);
  };
  
  const handleClick = (e:any) => {
    // e.preventDefault();
    // e.stopPropagation();
     fileInputRef.current?.click();

  };

  
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = () => {
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleRemoveImage = (e:any,index: number) => {
    e.preventDefault();
    e.stopPropagation();
    const newPreviews = previews.filter((_, i) => i !== index);
    const newFiles = value.filter((_: any, i: number) => i !== index);
    setPreviews(newPreviews);
    onChange(newFiles);
  };

  return (
    <div
      style={{
        padding: "0.7rem",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        border: uploadError || error ? "1px dashed #d32f2f" : "1px dashed #344767",
        borderRadius: "8px",
        cursor: "pointer",
        backgroundColor: isDragging ? "#E3F2FD" : "transparent",
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
       onClick={handleClick}
    >
      {previews.length > 0 ? (
        <>
   <Box display="flex" flexDirection="row" justifyContent={"space-between"} gap={0}>

  {/* Images Preview Section */}
  <Box
    display="flex"
    flexWrap="wrap"
    gap={2}
     px={2}
    sx={{
      borderRadius: "8px",
      position: "relative",
    }}
  >
    {previews?.map((preview, index) => (
      <Box
        key={index}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        {/* Image */}
        <img
          src={preview}
          alt={`product-${index}`}
          style={{
            borderRadius: "8px",
            height: "100px",
            maxWidth: "100px",
            objectFit: "contain",
          }}
        />

        {/* Remove Button */}
        <button
          type="button"
          onClick={(e) => handleRemoveImage(e,index)}
          style={{
            position: "absolute",
            top: "0px",
            right: "1px",
            border: "none",
            color: "white",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: "red",
          }}
        >
          &times;
        </button>
      </Box>
    ))}
            </Box>
            
     <Box display="flex" justifyContent="flex-end" alignItems={"start"}>
    <Box  >
                <BiPlusCircle fontSize="24px" color="primary" />
                <input
            type="file"
            accept="image/png,image/jpeg,image/jpg"
            hidden
            multiple
            onChange={handleImageChange}
            id="file-input-multi"
             ref={fileInputRef}
          />
    </Box>
  </Box>
</Box>

           </>
         ) : (
  <>
         <Box padding={3} textAlign={"center"}>
          <Box
            className="upload__image-dropzone-btn btnImage"
            >
          {t("store.AddFiles")}
          <input
            type="file"
            accept="image/png,image/jpeg,image/jpg"
            hidden
            multiple
            onChange={handleImageChange}
            id="file-input-multi"
             ref={fileInputRef}
          />
        </Box>
        <Typography sx={{ color: "#6D7175", my: 1,fontSize:"14px" }} className="image-accept">
                {t("product.Accepts")} .png, .jpg, .jpeg
                <br/>
                {t("recommendedResolution")}

        </Typography>
      </Box>
  </>
)}

        {!!error ? (
             <FormHelperText error>{error.message}</FormHelperText>
        ) : null}
          {uploadError && <FormHelperText error>{uploadError}</FormHelperText>}
    </div>
  );
};
