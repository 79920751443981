import React from "react";
import { TextInput, UploadImagesPreview } from "../../components/custom-inputs";
import { useForm } from "react-hook-form";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useCurrentMerchantSelector } from "../../@core/slice";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { useStore } from "../../@core/hooks";

interface Props extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
  submitFormHandler: (data: any) => void;
  isSubmitting: boolean;
}

export const AddSubCategoryForm: React.FC<Props> = ({
  closeModal,
  submitFormHandler,
  isSubmitting,
}) => {
  const { merchant } = useCurrentMerchantSelector();
  const { data: store } = useStore();
  console.log(merchant?.category,"merchant")
  console.log(store?.data?.category,"store")

  const { t } = useTranslation();
  const { control, handleSubmit ,formState:{errors}} = useForm({
    defaultValues: {
      nameAr: "",
      nameEn: "",
      image: "",
      parent: merchant?.category?._id || store?.data?.category?._id,
      store: merchant?._id,
    },
    mode:"all"
  });
  console.log("errors",errors);
  
  return (
    <form onSubmit={handleSubmit(submitFormHandler)}>
      <Box sx={{ marginBottom: "1rem" }}>
        <TextInput
          control={control}
          inputId="sub-category-name-Ar"
          name="nameAr"
          label={t("sub.SubCategoryNameِAr")}
           rules={{
             required: {
             value: true,
             message: t("product.Required"),
                    },
              }}
            error={errors?.nameAr}
        />
      </Box>
      <Box sx={{ marginBottom: "1rem" }}>
        <TextInput
          control={control}
          inputId="sub-category-nameEn"
          name="nameEn"
          label={t("sub.SubCategoryNameEn")}
          rules={{
           required: {
            value: true,
           message: t("product.Required"),
             },
          }}
           error={errors?.nameEn}
        />
      </Box>

      <Box sx={{ marginBottom: "1rem" }}>
        <UploadImagesPreview
          control={control}
          name="image"
          label={t("sub.Image")}
          isMultiple={false}
             rules={{
             required: {
              value: true,
               message: t("product.Required"),
               },
           }}
            errors={errors}

        />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "12px",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <LoadingButton
          type="submit"
          className="buttonHandleSubmit"
          size="large"
          loading={isSubmitting}
          disabled={isSubmitting}
          variant="contained"
        >
          {t("sub.AddSubCategory")}
        </LoadingButton>

        <Button
          type="button"
          className="formButton"
          variant="outlined"
          size="large"
          onClick={() => closeModal()}
        >
          {t("sub.Cancel")}
        </Button>
      </Box>
    </form>
  );
};
