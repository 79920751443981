import React, { useEffect, useMemo } from "react";
import { NavBar, Table } from "../../../../components";
import { useParams, useNavigate } from "react-router-dom";

//styles
import "./style.scss";

//Icons
import { CgFileDocument } from "react-icons/cg";
import { FiEdit } from "react-icons/fi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useProduct } from "../../../../@core/hooks";
import { useCurrentMerchantSelector } from "../../../../@core/slice";
import { useTranslation } from "react-i18next";
import {  AddOnData, FoodProduct } from "../../../../@core/models";
import { Typography } from "@mui/material";

export function ViewFoodProduct() {
  const navigation = useNavigate();

  const { t, i18n } = useTranslation();
  const { productId } = useParams();

  const isRTL = i18n.dir() === "rtl";

  const { merchant } = useCurrentMerchantSelector();
   const { data, isLoading, refetch } = useProduct({
     productId: productId as string,
     storeId: merchant!!._id,
   });

   useEffect(() => {
     refetch(); 
   }, [productId, merchant, refetch]);

  const details = data?.data as any;

  const columns = useMemo(
    () => [
      {
        title: t("product.name"),
        selector: (row: AddOnData) => (
          <div className="tableImage">
            <Typography>
              { row?.name}
            </Typography>
          </div>
        ),
      },
      {
        title: t("product.price"),
        selector: (row: AddOnData) => (
          <div className="tableImage">
            <Typography variant="button">{row?.price}</Typography>
          </div>
        ),
      },
      {
        title: t("product.Image"),
        selector: (row: AddOnData) => (
          <div className="tableImage">
            <img src={row?.image} alt={row?.name} />
          </div>
        ),
      },
    ],
    [t]
  );

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="viewProductContainer">
      <NavBar
        title={t("product.details.title")}
        hasBack={true}
        status={details?.status}
      />
      <div className="viewProductAction">
        <div className="routes">
          <span onClick={() => navigation("/products")}>
            {t("menuNavigation.products")}
          </span>

          {isRTL ? (
            <IoIosArrowBack fontWeight={900} size={20} />
          ) : (
            <IoIosArrowForward fontWeight={900} size={20} />
          )}
          <span style={{ color: "sandybrown" }}>
            {details?.name}
          </span>
        </div>
        <div className="editButton">
          <button
            onClick={() =>
              navigation(
                `/products/edit/${details._id?.toString()}/${
                  merchant?.type !== "Food" ? "stock" : "non-stock"
                }`
              )
            }
          >
            <FiEdit size={15} />
            {t("product.details.editProduct")}
          </button>
        </div>
      </div>
      <div className="content">
        <div>
          <div className="header">
            <CgFileDocument />
            {t("product.details.title")}
          </div>
          <div className="container">
            <div className="productImages">
              <div>
                <div> {t("product.details.productImage")} </div>
                <img src={details?.mainImage} alt="mainImage" />
              </div>
              <div>
                {(details as FoodProduct).addOns?.length ? (
                  <div>{t("product.MealImages")}</div>
                ) : null}
                <div>
                  <>
                    {(details as FoodProduct).addOns
                      ? (details as FoodProduct).images?.map((img, index) => (
                          <img src={img} alt="Images" key={index} />
                        ))
                      : null}
                  </>
                </div>
              </div>
            </div>
            <div className="productInfo">
              <div>
                <div className="title">{t("product.details.productSku")}</div>
                <div className="data">{details.sku}</div>
              </div>
              <div>
                <div className="title">{t("product.details.productName")}</div>
                <div className="data">
                  {details?.name}
                </div>
              </div>
              <div>
                <div className="title">
                  {t("product.details.productCategory")}
                </div>
                <div className="data">{details.category.name}</div>
              </div>
            </div>
            <hr />
            <div>
              <div className="title">
                {t("product.details.productDiscount")}
              </div>
              <div className="data">
                {details?.discountPrice
                  ? details.discountPrice + " KWD"
                  : "N/A"}
              </div>
            </div>
            <hr />
            <div>
              <div className="title">
                {t("product.details.productDescription")}
              </div>
              <div className="data">
                { details?.description}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="header">
              <CgFileDocument />
              {t("product.details.productPrice")}
            </div>
            <div className="container">
              <div className="title">{t("product.details.productPrice")}</div>
              <div className="data">
                {details?.price} {t("currency.kwd")}{" "}
              </div>
            </div>
          </div>
          <div>
            <div className="header">
              <CgFileDocument />
              {t("product.details.productTags")}
            </div>
            {details?.tags?.length ? (
              <div className="container">
                {details?.tags?.map(
                  (
                    tag: { name: string },
                    key: React.Key | null | undefined
                  ) => (
                    <Tag name={tag.name} key={key} />
                  )
                )}
              </div>
            ) : null}
          </div>

        </div>
                  <div>
            <div className="header">
              <CgFileDocument />
             {t("Avarge Rating")}
            </div>
            <div className="container">
              <div className="title">{t("Avarge Rating")}</div>
              <div className="data">{details?.avgRating}</div>
            </div>
        </div>
        
        <div className="varientsContainer">
          <div className="header">
            <CgFileDocument />
            {t("product.Add ons")}
          </div>
          <div className="tableContainer" id="tableContainer">
            <Table
              columns={columns}
              data={(details as FoodProduct).addOns as AddOnData[]}
            />
          </div>
        </div>

        <div className="varientsContainer">
          <div className="header">
            <CgFileDocument />
            {t("Ratings")}
          </div>
<div className="tableContainer" id="tableContainer">
    <table id="table">
    <thead>
      <tr>
        <th className="tableHead" >{t("Customer Name")}</th>
         <th className="tableHead" >{t("Rating")}</th>
         <th className="tableHead" >{t("Title")}</th>
        <th colSpan={2} className="tableHead" >{t("Comment")}</th>
      </tr>
    </thead>
    <tbody>
      {details?.ratings?.map((rating:any) => (
        <tr key={rating._id}>
          <td>{rating.customer.name}</td>
          <td>{rating.rating}</td>
          <td>{rating?.title||"-"}</td>
          <td colSpan={2}>{rating?.comment ||"-"}</td>

        </tr>
      ))}
    </tbody>
  </table>
</div>

        </div>
      </div>
    </div>
  );
}

type TagProps = {
  name: string;
};

const Tag = ({ name }: TagProps) => (
  <div className="tagContainer">
    <span>{name}</span>
  </div>
);
