import React from "react";
import { useListCategory } from "../../../@core/hooks";
import { useCreateProduct } from "../../../@core/hooks/mutation/product/use-create-product.hook";
import { useCurrentMerchantSelector } from "../../../@core/slice";
import { AddFoodProductFormTemplate } from "./add-food-product-form.template";
import "./add-product.scss";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface Props extends React.HTMLProps<HTMLDivElement> {}

export const AddFoodProductForm: React.FC<Props> = () => {
  const { merchant } = useCurrentMerchantSelector();
  const { t } = useTranslation();
  const navigte = useNavigate();
  const { mutate: addFoodProduct, isLoading: loadingAddProduct } =
    useCreateProduct();

  const { data: categories } = useListCategory();

  const storeId = merchant?._id as string;

  const foodCategoryId = merchant?.category._id as string;

  const submitForm = (rowValues: any) => {
    console.log(rowValues,"rowValues")
    const updatedValues = {
      ...rowValues,
      addOns: rowValues?.addOns?.map((addOn: any) => ({
        ...addOn,
        image:
          typeof addOn?.image === "string"
            ? addOn?.image
            : addOn?.image,
      })),

      mainImage:
        typeof rowValues?.mainImage[0] === "string"
          ? rowValues?.mainImage[0]
          : (rowValues?.mainImage[0] as any)?.file,
      images: rowValues?.images?.map((image: any) => {
        return typeof image === "string" ? image : image?.file;
      }),
    };
    console.log(updatedValues,"updatedValues")

    // add updated values to formData, if data is array Stringify it
    const formData = new FormData();

    Object.keys(updatedValues).forEach((key) => {
      if (key === "addOns") {
        updatedValues[key]?.forEach((addOn: any, index: number) => {
          if (addOn?.image) {
                console.log(addOn?.image,"addOn?.image")

            formData.append(`addOns[${index}].image`, addOn?.image);
          }
        });
      }
      if (key === "images") {
        updatedValues[key]?.forEach((image: any, index: number) => {
          formData.append(`images[${index}]`, image);
        });
      }
      if (Array.isArray(updatedValues[key])) {
        formData.append(key, JSON.stringify(updatedValues[key]));
      } else {
        formData.append(key, updatedValues[key]);
      }
    });

    addFoodProduct(
      { storeId, body: formData },
      {
        onSuccess: () => {
          toast.success(t("product.success"));
          navigte("/products");
        },
        onError: () => {
          toast.error(t("product.faild"));
        },
      }
    );
  };

  return (
    <>
      <AddFoodProductFormTemplate
        storeId={storeId}
        submitForm={submitForm}
        isSubmitting={loadingAddProduct}
        categories={categories}
        foodCategoryId={foodCategoryId}
      />
    </>
  );
};
