import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translations from "./translations.json";

// i18n
//   .use(initReactI18next)
//   .use(LanguageDetector)
//   .init({
//     fallbackLng: "en",
//     resources: translations,
//     interpolation: {
//       escapeValue: false,
//     },
//   });

  i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en", 
    resources: translations,
    supportedLngs: ["en", "ar"],
    load: "languageOnly", 
    interpolation: {
      escapeValue: false,
    },
      detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
      lookupLocalStorage: "i18nextLng",
    },
    react: {
      useSuspense: false,
    }
  });
  
 i18n.on("languageChanged", (lng) => {
  const normalizedLang = lng.split("-")[0];
  if (normalizedLang !== lng) {
    i18n.changeLanguage(normalizedLang);
  }
});
export default i18n;
