import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./style.scss";
import { useLogin } from "../../@core/hooks";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../@core/slice";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { theme } from "../../assets/styles/mui-theme";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";

type LoginDto = {
  email: string;
  password: string;
};

function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const { mutate: login } = useLogin();
  const navigte = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const environment = process.env.NODE_ENV;

  const onSubmit = (values: LoginDto) => {
    login(
      { email: values.email, password: values.password },
      {
        onSuccess: (data) => {
          dispatch(loginSuccess(data));
          toast.success(`${t("auth.Welcome")} ${data.user?.name ?? ""}`);
          navigte("/");
        },
        onError: (err:any) => {
          toast.error(err?.response?.data?.message);
          toast.error(t("auth.faildLogin"));
        },
      }
    );
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginDto>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  return (
    <div className="loginContainer">
      <div className="container">
        <div className="logo">
          <img src="/logoDark.png" alt="logo" />
          <span>BASE</span>
        </div>

        {environment === "development" && (
          <div>
            <h3>Development Mode</h3>
            email: <span>ms.slama@icloud.com</span>
            <br />
            password: <span>Moha@123</span>
          </div>
        )}

        <div
          className="loginTitle"
          style={{
            textAlign: "center",
          }}
        >
          <h3 style={{ fontWeight: "700" }}>{t("auth.Login")}</h3>
          <h5 style={{ fontWeight: "400" }}>{t("auth.EnterText")}</h5>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Grid
              container
              spacing={2}
              style={{ justifyContent: "center", width: "fit-content" }}
            >
              <Grid item xs={12} md={8}>
                <FormControl fullWidth>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t("emp.Emailrequired"),
                      },
                      required: {
                        value: true,
                        message: t("emp.Pattern"),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label={t("auth.Email")}
                        type="string"
                        placeholder=""
                        error={!!errors.email}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.email}>
                    {errors.email?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl fullWidth>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t("emp.PasswordRequired"),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label={t("emp.Password")}
                        type={showPassword ? "text" : "password"}
                        placeholder=""
                        error={!!errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={togglePasswordVisibility}
                                edge="end"
                                size="small"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.password}>
                    {errors.password?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={3}
                >
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="basePrimary"
                    style={{ width: "50%" }}
                  >
                    {t("auth.SignIN")}
                  </Button>
                  <div>
                    {t("auth.DontHavAaccount")}
                    <Link to="/signup"> {t("auth.SignUp")}</Link>
                  </div>
                  <div>
                    {t("auth.ForgotPassword")}
                    <Link to="/forget-password">
                      {" "}
                      {t("auth.ForgotPassword")}
                    </Link>
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default Login;
