import React, { useEffect, useState } from "react";
import { TextInput, UploadImagesPreview } from "../../components/custom-inputs";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, FormControl } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useCurrentMerchantSelector } from "../../@core/slice";
import { useTranslation } from "react-i18next";
import { useEditSubCategory } from "../../@core/hooks/mutation/categories/sub-categories.hook";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import "./style.scss";
import { ImageUploader } from "../../components/StockProduct/imageUploader";

interface Props extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
  setShowModalEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedItem: React.Dispatch<any>;
  selectedItem: any;
}

export const EditSubCategoryForm: React.FC<Props> = ({
  closeModal,
  setShowModalEdit,
  selectedItem,
  setSelectedItem,
}) => {
  const { merchant } = useCurrentMerchantSelector();
  const { mutate: editSubCategory, isLoading: editLoading } =
    useEditSubCategory();
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const EngLang = i18n.language === "en";

  const { control, handleSubmit, setValue ,formState:{errors}} = useForm({
    mode:"all",
    defaultValues: {
      nameEn: EngLang
        ? selectedItem?.name || ""
        : selectedItem?.translation?.name || "",
      nameAr: EngLang
        ? selectedItem?.translation?.name || ""
        : selectedItem?.name || "",
      image: selectedItem?.image ?? [],
      parent: merchant?.category?._id||"",
      store: merchant?._id,
    },
  });

  useEffect(() => {
    if (selectedItem) {
      setValue(
        "nameEn",
        EngLang
          ? selectedItem.name || " "
          : selectedItem.translation?.name || " "
      );
      setValue(
        "nameAr",
        EngLang ? selectedItem.translation?.name || "" : selectedItem.name || ""
      );
      setValue("image", selectedItem.image);
    }
  }, [EngLang, selectedItem, setValue]);

  const onSubmitFormHandler = async (data: any) => {
    const formData = new FormData();
    formData.append("nameEn", data.nameEn);
    formData.append("nameAr", data.nameAr);
    formData.append("parent", data.parent);
    formData.append("store", data.store);
            // formData.append("image", data.image);
      if (data.image instanceof File) {
        formData.append("image", data.image);
      }
    editSubCategory(
      { id: selectedItem._id, data: formData },
      {
        onSuccess: () => {
          toast.success(t("success.successfullyUpdated"));
          queryClient.invalidateQueries("sub-categories");
          setSelectedItem(null);
          setShowModalEdit(false);
        },
        onError: (err:any) => {
          toast.error(t("errors.faildUpdated"));
          toast.error(err?.response?.data?.message);

        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmitFormHandler)}>
      <Box sx={{ marginBottom: "1rem" }}>
        <TextInput
          control={control}
          inputId="sub-category-name-Ar"
          name="nameAr"
          label={t("sub.SubCategoryNameِAr")}
           rules={{
             required: {
             value: true,
             message: t("product.Required"),
                    },
              }}
            error={errors?.nameAr}
        />
      </Box>
      <Box sx={{ marginBottom: "1rem" }}>
        <TextInput
          control={control}
          inputId="sub-category-nameEn"
          name="nameEn"
          label={t("sub.SubCategoryNameEn")}
          rules={{
           required: {
            value: true,
           message: t("product.Required"),
             },
          }}
           error={errors?.nameEn}
        />
      </Box>

      <Box sx={{ marginBottom: "1rem" }}>
                        <Controller
                        name="image"
                        control={control}
                       defaultValue={null}
                        rules={{
                              required: {
                               value: true,
                               message: t("product.Required"),
                            },
                          }}
                        render={({ field: { onChange, value } }) => (
                          <ImageUploader
                            onChange={onChange}
                            value={value}
                           error={errors?.image}  />
                        )}
                      />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "12px",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <LoadingButton
          type="submit"
          className="buttonHandleSubmit"
          size="large"
          loading={editLoading}
          disabled={editLoading}
          variant="contained"
        >
          {t("sub.EditSubCategory")}
        </LoadingButton>

        <Button
          type="button"
          className="formButton"
          variant="outlined"
          size="large"
          onClick={() => closeModal()}
        >
          {t("sub.Cancel")}
        </Button>
      </Box>
    </form>
  );
};
