import { useQuery } from "react-query";
import { StoreResponse } from "../../../models";
import { fetcher } from "../../../api/fetcher.api";

export const useStore = () =>
  useQuery<StoreResponse>("store", () => fetcher("store"), {
    keepPreviousData: false,
    cacheTime: 0,
    
  });
