import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material";

export const LanguageSwitcher: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language || "en");

  useEffect(() => {
    // Ensure the correct language persists on first load
    const savedLanguage = localStorage.getItem("i18nextLng") || "en";
    setCurrentLanguage(savedLanguage);
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage).then(() => {
      setCurrentLanguage(newLanguage);
      localStorage.setItem("i18nextLng", newLanguage);
    });
  };

  const languages = [
    { code: "en", label: t("English") || "English" },
    { code: "ar", label: t("اللغة العربية") || "Arabic" },
  ];

  return (
    <Select
      size="small"
      sx={{ width: "130px", textAlign: "center" }}
      autoWidth
      value={currentLanguage}
      onChange={handleChangeLanguage}
    >
      {languages.map((lang) => (
        <MenuItem
          sx={{ width: "120px", mx: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}
          key={lang.code}
          value={lang.code}
          style={lang.code === currentLanguage ? { display: "none" } : {}}
        >
          {lang.label}
        </MenuItem>
      ))}
    </Select>
  );
};
