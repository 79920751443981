import React, { useState, useEffect } from "react";
import { Box, Button, FormHelperText, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FieldError } from "react-hook-form";
import "./style.scss";

interface Props {
  value: any;
  onChange: (file: File | null) => void;
  error?: FieldError | any;
  hight?:string
}

const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];

export const ImageUploader = ({ onChange, value, error ,hight="100px"}: Props) => {
  const [preview, setPreview] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (value instanceof Blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(value);
    } else if (typeof value === "string") {
      setPreview(value);
    } else {
      setPreview(null);
    }
  }, [value]);

  const validateFile = (file: File) => {
    if (!allowedFormats.includes(file.type)) {
      setUploadError(t("errors.invalidImageFormat"));
      return false;
    }
    setUploadError(null);
    return true;
  };
 const handleClick = () => {
   document.getElementById("file-input")?.click();
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && validateFile(file)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
        onChange(file);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
      onChange(null);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file && validateFile(file)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
        onChange(file);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
      onChange(null);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = () => {
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleRemoveImage = () => {
    setPreview(null);
    onChange(null);
  };

  return (
    <div>
      <div
        style={{
          padding: "1.2rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          border: uploadError || error ? "1px dashed #d32f2f" : "1px dashed #344767",
          borderRadius: "8px",
          cursor: "pointer",
          backgroundColor: isDragging ? "#E3F2FD" : "transparent",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onClick={handleClick}
    
      >
        {preview ? (
          <Stack>
            <Box
             sx={{
               position: "relative",
            display: "flex",
              flexDirection: "column",
          alignItems: "center",
  }}
>
  <img
    src={preview}
    alt={`product`}
    style={{
      borderRadius: "8px",
      height:hight ,
      width:hight,
      maxWidth: "200px",
      objectFit: "contain",
    }}
  />
  <button
    className=""
    type="button"
    onClick={handleRemoveImage}
    style={{
      position: "absolute",
      top: "2px",
      right: "1px",
      backgroundColor: "red",
      border: "none",
      color: "white",
      borderRadius: "50%",
      width: "20px",
      height: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    }}
  >
    &times;
  </button>
</Box>
         </Stack>
        ) : (
          <div >
            <Box mt={1.5} textAlign={"center"}>
              <Box
                 className="upload__image-dropzone-btn btnImage"
                >
                {t("Add Photo")}
                <input
                  type="file"
                  accept={allowedFormats.join(",")}
                  hidden
                    onChange={handleImageChange}
                     id="file-input"
                />

                </Box>

              <Typography
                sx={{ color: "#6D7175", my: 2 ,fontSize:"14px"}}
                className="image-accept"
              >
                {t("product.Accepts")} .png, .jpg, .jpeg
                <br/>
                {t("recommendedResolution")}
              </Typography>
            </Box>
          </div>
        )}
      </div>
      {uploadError && <FormHelperText error>{uploadError}</FormHelperText>}
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </div>
  );
};
