import instance from "../../../utils/axios.util";

interface UpdateOrderProps {
  storeId: string;
  status: string;
  orderId: string;
  addressDetails?: string;
  latitude?: any;
  longitude?: any;
}

export async function UpdateOrderStatus({
  storeId,
  status,
  orderId,
  addressDetails,
  latitude,
  longitude,
}: UpdateOrderProps) {
  try {
    // Only include the body if addressDetails is defined
    const body = addressDetails
      ? {
          addressDetails,
          latitude,
          longitude,
        }
      : undefined;

    const res = await instance.patch(
      `${storeId}/order/${status}/${orderId}`,
      body
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}
