import { FormControl, InputLabel } from '@mui/material';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Controller } from 'react-hook-form';

interface Props {
	name: string;
	inputId: string;
	label: string;
	control: any;
	rules?: { [key: string]: any };
}

export const UploadImageInput: React.FC<Props> = ({
	inputId,
	name,
	label,
	control,
	rules = {},
}) => {
	return (
    <div>
      <InputLabel sx={{ textAlign: "initial !important" }} variant="standard">
        {label}
      </InputLabel>
      <FormControl fullWidth>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <FileUploader
              name={field.name}
              id={`${inputId}-image`}
              accept="image/*"
              types={["JPEG", "PNG","JPG"]}
              onChange={(e: any) => {
                console.log(e);
              }}
              style={{
                width: "100%",
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
};
